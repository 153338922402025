import { Component } from 'react';

class Api extends Component {
  getTeamMembers = () => {
    return {
      principals: [
        {
          name: "Sally Evans, M.A., RPA",
          image: process.env.PUBLIC_URL + '/images/team/Sally.jpg',
          email: "sally@evans-deshazo.com",
          positions: [
            "Principal Archaeologist"
          ],
          experience: 20
        },
        {
          name: "Stacey De Shazo, M.A.",
          image: process.env.PUBLIC_URL + '/images/team/Stacey.jpg',
          email: "stacey@evans-deshazo.com",
          positions: [
            "Principal Architectural Historian"
          ],
          experience: 20
        }
      ],
      staff: [
        {
          name: "Gina Zavala, M.A.",
          image: process.env.PUBLIC_URL + '/images/team/Gina.jpg',
          education: "M.A. Archaeology",
          positions: [
            "Archaeologist"
          ],
          experience: 17
        },
        {
          name: "Bee Thao, M.A.",
          image: process.env.PUBLIC_URL + '/images/team/Bee.jpg',
          education: "M.A. Cultural Resource Management",
          positions: [
            "Archeologist"
          ],
          experience: 8
        },
        {
          name: "James Allen, M.S., PG",
          image: process.env.PUBLIC_URL + '/images/team/James.png',
          education: "M.S. Geology",
          positions: [
            "Senior Geologist"
          ],
          experience: 20
        },
        {
          name: "Selena Rose Roloson, B.A.",
          image: process.env.PUBLIC_URL + '/images/team/Selena.png',
          education: "B.A. Archaeology",
          positions: [
            "Field Director",
            "Archeologist"
          ],
          experience: 13
        }
      ]
    }
  }
  getIntro = () => {
    return {
      title: "Working together, to make the past relevant today",
      message: "EDS provides the highest quality cultural resource management, historical research, Archaeological and Historical Architectural surveys and assessments for public and private clients across the country. Since 2014, we have been helping our clients meet the goals of their present-day projects.",
      aboutMessage: "Evans & De Shazo, Inc. (EDS) is a certified Women Business Enterprise (WBE), Small/Micro Business Enterprise (SBE), and Disadvantaged Business Enterprise (DBE) that provides high-quality Cultural Resource Management including Archaeological and Architectural History. We specialize in identifying, documenting, evaluating, and mitigating impacts to cultural resources such as prehistoric and historic archaeological sites, historic built-environment resources, paleontological resources, and cultural landscapes.",
    }
  }
  getNavLinks = () => {
    return [
      {
        url: "#",
        text: "About us",
        hasDropdown: true,
        dropdownLinks: [
          {
            url: "/owners",
            text: "Meet the Owners"
          },
          {
            url: "/team",
            text: "Our Team"
          },
          {
            url: "/projects",
            text: "Highlighted Projects"
          }
        ]
      },
      {
        url: "/services",
        text: "Services",
        hasDropdown: true,
        dropdownLinks: [
          {
            url: "/services#Archaeology",
            text: "Archaeology"
          },
          {
            url: "/services#HistoricalEvaluations",
            text: "Historical Evaluations"
          },
          {
            url: "/services#Environmental Services",
            text: "Environmental Services"
          },
          {
            url: "/services#History",
            text: "History / Ethnography"
          },
          {
            url: "/services#Reporting",
            text: "Reporting"
          },
          {
            url: "/services#GISMapping",
            text: "GIS Mapping"
          },
          {
            url: "/services#Compliance",
            text: "Compliance"
          },
        ]
      },
      {
        url: "/blog",
        text: "Blog",
        hasDropdown: false
      },
      {
        url: "mailto:info@evans-deshazo.com",
        text: "Contact",
        hasDropdown: false
      }
    ]
  }
  getProjects = () => {
    return [
      {
        id: 1,
        type: "20% Tax Credit Applications",
        title: "1914 Hotel Harrison",
        location: "Oakland, CA",
        dateRange: "2017 - 2019",
        content: "",
        url: process.env.PUBLIC_URL + '/images/projects/1914_hotel_harrison/thumbnail.jpg',
        images: [
          {
            id: 1,
            url: process.env.PUBLIC_URL + '/images/projects/1914_hotel_harrison/thumbnail.jpg',
            alt: "",
            caption: ""
          },
          {
            id: 2,
            url: process.env.PUBLIC_URL + '/images/projects/1914_hotel_harrison/Photo1.jpg',
            alt: "",
            caption: ""
          },
          {
            id: 3,
            url: process.env.PUBLIC_URL + '/images/projects/1914_hotel_harrison/Photo2.jpg',
            alt: "",
            caption: ""
          },
        ]
      },
      {
        id: 2,
        type: "Historic American Building Survey",
        title: "Yosemite Valley",
        location: "Yosemite National Park, CA",
        dateRange: "2017 - 2019",
        content: "",
        url: process.env.PUBLIC_URL + '/images/projects/yosemite_valley/thumbnail.jpg',
        images: [
          {
            id: 4,
            url: process.env.PUBLIC_URL + '/images/projects/yosemite_valley/thumbnail.jpg',
            alt: "1939 General Office Building",
            caption: "1939 General Office Building"
          },
          {
            id: 5,
            url: process.env.PUBLIC_URL + '/images/projects/yosemite_valley/Photo1.jpg',
            alt: "Architectural Drawing of the General Office Building, by Eldridge Theodore Spencer, dated 1939",
            caption: "Architectural Drawing of the General Office Building, by Eldridge Theodore Spencer, dated 1939"
          },
          {
            id: 6,
            url: process.env.PUBLIC_URL + '/images/projects/yosemite_valley/Photo2.jpg',
            alt: '"1939 General Office Building" photograph courtesy of Dennis Hill.',
            caption: '"1939 General Office Building" photograph courtesy of Dennis Hill.'
          }
        ]
      },
      {
        id: 3,
        type: "Historic Resource Assessment and Context Statement",
        title: "Old Fisherman’s Wharf",
        location: "Monterey, CA",
        dateRange: "2017-2018",
        content: "",
        url: process.env.PUBLIC_URL + '/images/projects/old_fishermans_wharf/thumbnail.jpg',
        images: [
          {
            id: 6,
            url: process.env.PUBLIC_URL + '/images/projects/old_fishermans_wharf/thumbnail.jpg',
            alt: "Harbor House on Old Fisherman’s Wharf, 2017",
            caption: "Harbor House on Old Fisherman’s Wharf, 2017"
          },
          {
            id: 7,
            url: process.env.PUBLIC_URL + '/images/projects/old_fishermans_wharf/Photo1.jpg',
            alt: "Old Fisherman’s Wharf, 2017",
            caption: "Old Fisherman’s Wharf, 2017"
          },
          {
            id: 8,
            url: process.env.PUBLIC_URL + '/images/projects/old_fishermans_wharf/Photo2.jpg',
            alt: "Aerial Survey Map, Old Fisherman’s Wharf",
            caption: "Aerial Survey Map, Old Fisherman’s Wharf"
          }
        ]
      },
      {
        id: 4,
        type: "Historic Resource Evaluation and Standards Review",
        title: "1925 Colonial Revival House",
        location: "Petaluma, CA",
        dateRange: "2016 - 2019",
        content: "",
        url: process.env.PUBLIC_URL + '/images/projects/1925_colonial_revival_house/thumbnail.jpg',
        images: [
          {
            id: 8,
            url: process.env.PUBLIC_URL + '/images/projects/1925_colonial_revival_house/thumbnail.jpg',
            alt: 'Sartori "Cream Cup" Dairy Ranch house on Casa Grande, Petaluma',
            caption: 'Sartori "Cream Cup" Dairy Ranch house on Casa Grande, Petaluma'
          },
          {
            id: 9,
            url: process.env.PUBLIC_URL + '/images/projects/1925_colonial_revival_house/Photo1.jpg',
            alt: "Character-defining details",
            caption: "Character-defining details"
          },
          {
            id: 10,
            url: process.env.PUBLIC_URL + '/images/projects/1925_colonial_revival_house/Photo2.jpg',
            alt: "1925 Details",
            caption: "1925 Details"
          }
        ]
      },
      {
        id: 5,
        title: "Identification of Native American Human Remains Within Existing Collections",
        type: "NAGPRA",
        location: "Fresno, CA",
        content: "",
        url: process.env.PUBLIC_URL + '/images/projects/nagpra/thumbnail.jpg',
        images: [
          {
            id: 11,
            url: process.env.PUBLIC_URL + '/images/projects/nagpra/thumbnail.jpg',
            alt: "NAGPRA",
            caption: "NAGPRA"
          },
          {
            id: 12,
            url: process.env.PUBLIC_URL + '/images/projects/nagpra/Photo1.jpg',
            alt: "Fresno City College Logo",
            caption: "Fresno City College Logo"
          },
          {
            id: 13,
            url: process.env.PUBLIC_URL + '/images/projects/nagpra/Photo2.jpg',
            alt: "Sally in the archaeology lab at Fresno City College",
            caption: "Sally in the archaeology lab at Fresno City College"
          }
        ]
      },
      {
        id: 6,
        type: "City-Wide Record Search and GIS Cultural Resource Mapping",
        title: "Gilroy GIS Database Development",
        location: "Gilroy, CA",
        dateRange: "2016 - 2016",
        content: "",
        url: process.env.PUBLIC_URL + '/images/projects/gilroy_mapping/thumbnail.jpg',
        images: [
          {
            id: 14,
            url: process.env.PUBLIC_URL + '/images/projects/gilroy_mapping/thumbnail.jpg',
            alt: "GIS Data Layers of Cultural Resources",
            caption: "GIS Data Layers of Cultural Resources"
          },
          {
            id: 15,
            url: process.env.PUBLIC_URL + '/images/projects/gilroy_mapping/Photo1.jpg',
            alt: "Forms were hyperlinked in the database for easy access and viewing",
            caption: "Forms were hyperlinked in the database for easy access and viewing"
          },
          {
            id: 16,
            url: process.env.PUBLIC_URL + '/images/projects/gilroy_mapping/Photo2.jpg',
            alt: "Databases were created providing baseline data on cultural resources",
            caption: "Databases were created providing baseline data on cultural resources"
          }
        ]
      },
      {
        id: 7,
        title: "Cultural and Paleontological Resources Study for the Southern Las Trampas Land Use Plan Amendment Project",
        type: "Cultural and Paleontological Resource Study",
        location: "San Ramon, California",
        dateRange: "2017 - 2018",
        content: "",
        url: process.env.PUBLIC_URL + '/images/projects/las_trampas/thumbnail.jpg',
        images: [
          {
            id: 17,
            url: process.env.PUBLIC_URL + '/images/projects/las_trampas/thumbnail.jpg',
            alt: "EDS surveyed 4.5 miles of new trails and 2-acres of staging area",
            caption: "EDS surveyed 4.5 miles of new trails and 2-acres of staging area"
          },
          {
            id: 18,
            url: process.env.PUBLIC_URL + '/images/projects/las_trampas/Photo1.jpg',
            alt: "Boulder of fossil rich sandstone that likely correlates to the late Miocene Briones Formation was identified",
            caption: "Boulder of fossil rich sandstone that likely correlates to the late Miocene Briones Formation was identified"
          },
          {
            id: 19,
            url: process.env.PUBLIC_URL + '/images/projects/las_trampas/Photo2.jpg',
            alt: "EDS also competed a Historic Resource Evaluation of the existing ranch structures within the project area",
            caption: "EDS also competed a Historic Resource Evaluation of the existing ranch structures within the project area"
          }
        ]
      },
      {
        id: 8,
        title: "Cultural Resources Study, Historic Resource Evaluation and Archaeological Monitoring for Petaluma Hills Farm, LLC",
        type: "Cultural Resource Study, Historic Resource Evaluation, and Archaeological Monitoring for Compliance with Sonoma County Cannabis Land Use Ordinance No. 6189",
        location: "Petaluma, CA",
        dateRange: "2017 - 2018",
        content: "",
        url: process.env.PUBLIC_URL + '/images/projects/purvine/thumbnail.jpg',
        images: [
          {
            id: 17,
            url: process.env.PUBLIC_URL + '/images/projects/purvine/thumbnail.jpg',
            alt: "Archaeological Survey of the former location of the ca. 1853 Iowa School building within the Project Area",
            caption: "Archaeological Survey of the former location of the ca. 1853 Iowa School building within the Project Area"
          },
          {
            id: 18,
            url: process.env.PUBLIC_URL + '/images/projects/purvine/Photo1.jpg',
            alt: "Archaeological Monitoring during demolition of a barn foundation",
            caption: "Archaeological Monitoring during demolition of a barn foundation"
          },
          {
            id: 19,
            url: process.env.PUBLIC_URL + '/images/projects/purvine/Photo2.jpg',
            alt: "1939 photo of former Iowa School building formerly within the Project Area",
            caption: "1939 photo of former Iowa School building formerly within the Project Area"
          }
        ]
      },
      {
        id: 9,
        title: "Cultural Resources Study for the Proposed Wilsonville Storm Outfalls Project",
        type: "Cultural Resource Study",
        location: "Wilsonville, Oregon",
        dateRange: "2017 - 2017",
        content: "",
        url: process.env.PUBLIC_URL + '/images/projects/wilsonville/thumbnail.jpg',
        images: [
          {
            id: 17,
            url: process.env.PUBLIC_URL + '/images/projects/wilsonville/thumbnail.jpg',
            alt: "Field Survey of Project Area",
            caption: "Field Survey of Project Area"
          },
          {
            id: 18,
            url: process.env.PUBLIC_URL + '/images/projects/wilsonville/Photo1.jpg',
            alt: "Field Survey",
            caption: "Field Survey"
          },
          {
            id: 19,
            url: process.env.PUBLIC_URL + '/images/projects/wilsonville/Photo2.jpg',
            alt: "Field survey included four exploratory shovel probes and four exploratory hand augers",
            caption: "Field survey included four exploratory shovel probes and four exploratory hand augers"
          }
        ]
      }
    ]
  }
  getImages = () => {
    return {
      projects: this.getProjects(),
      services: [
        {id: 1, url: process.env.PUBLIC_URL + '/images/services2.jpg', alt: "",
        overlay: {
            title: "Archaeology",
            details: "EDS provides archaeological resource surveys, local, state and National Register evaluations, construction monitoring, data recovery, burial recovery, artifact analysis, and public interpretation and outreach."
        }},
        {id: 2, url: process.env.PUBLIC_URL + '/images/services6-2.jpg', alt: "",
        overlay: {
            title: "Historic Architecture",
            details: "EDS specializes in Historic Resource Evaluations, Secretary of Interior's Standards Review, Compliance Guidance and Mitigation, Historic Preservation Tax Credit Applications, and HABS/HAER Documentation."
        }},
        {id: 3, url: process.env.PUBLIC_URL + '/images/services3.jpg', alt: ""},
        {id: 4, url: process.env.PUBLIC_URL + '/images/services4.jpg', alt: ""},
        {id: 5, url: process.env.PUBLIC_URL + '/images/services5.jpg', alt: ""}
      ]
    }
  }
  getPosts = () => {
    return [
      // {
      //   id: 1,
      //   created_at: "January 1, 2018",
      //   updated_at: "January 10, 2018",
      //   title: "The Great Gatsby",
      //   content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      //   images: [
      //     {id: 1, url: process.env.PUBLIC_URL + '/images/placeholder.jpg', alt: "placeholder"},
      //     {id: 2, url: process.env.PUBLIC_URL + '/images/placeholder2.jpg', alt: "placeholder"}
      //   ]
      // },
      // {
      //   id: 2,
      //   author: "Lorem Ipsum",
      //   created_at: "February 15, 2018",
      //   updated_at: "February 15, 2018",
      //   title: "Lord of the Rings",
      //   content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      //   images: [
      //     {id: 3, url: process.env.PUBLIC_URL + '/images/placeholder3.jpg', alt: "placeholder"},
      //     {id: 4, url: process.env.PUBLIC_URL + '/images/placeholder6.jpg', alt: "placeholder"}
      //   ]
      // },
      // {
      //   id: 3,
      //   author: "Foo Bar",
      //   created_at: "February 20, 2018",
      //   updated_at: "April 01, 2018",
      //   title: "The Hobbit",
      //   content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      //   images: [
      //     {id: 5, url: process.env.PUBLIC_URL + '/images/placeholder6.jpg', alt: "placeholder"},
      //     {id: 6, url: process.env.PUBLIC_URL + '/images/placeholder4.jpg', alt: "placeholder"}
      //   ]
      // },
      // {
      //   id: 4,
      //   author: "Bar Buzz",
      //   created_at: "January 1, 2016",
      //   updated_at: "January 10, 2017",
      //   title: "Mamamia",
      //   content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      //   images: [
      //     {id: 7, url: process.env.PUBLIC_URL + '/images/placeholder5.jpg', alt: "placeholder"},
      //     {id: 8, url: process.env.PUBLIC_URL + '/images/placeholder3.jpg', alt: "placeholder"}
      //   ]
      // }
    ]
  }
  getFooter = () => {
    return {
      company: "Evans & DeShazo, Inc.",
      address: "1141 Gravenstein Highway South | Sebastopol | CA | 95472 |",
      phone: "(707) 823-7400",
      email: "info@Evans-DeShazo.com"
    }
  }
  getServices = () => {
    return [
      {
        id: 1,
        names: ["Archaeology"],
        capabilities: [
          "Historic and Prehistoric Archaeology",
          "Inventory Surveys",
          "Significance Evaluations",
          "Construction Monitoring",
          "Data Recovery Excavations",
          "Geoarchaeological Investigations",
          "Paleontological Resource Studies",
          "Burial Recovery and Osteological Analysis",
          "Artifact Analysis, Conservation and Curation",
          "Public Outreach and Education",
          "Cultural Resource Sensitivity Training",
          "Native American Monitor Training"
        ]
      },
      {
        id: 2,
        names: ["Historical Evaluations"],
        capabilities: [
          "Historic Resource Evaluations (HRE)",
          "National Register of Historic Places Nominations",
          "Rehabilitation and Reuse Planning",
          "Historic American Engineering Record (HAER)",
          "Historic American Building Survey (HABS) Documentation",
          "Historic Architectural Design Consultation",
          "20% Tax Credit Applications",
          "Preservation Planning",
          "State Historical Building Code (SHBC) Guidance",
          "Planning, Compliance, and Guidance of Historic Zoning Districts and Policies",
          "Identifying and Solving Complicated Mitigation",
          "Historic Resource Context Statements"
        ]
      },
      {
        id: 3,
        names: ["Environmental Services"],
        capabilities: [
          "Cultural Resources Assessments",
          "Paleontological Resource Assessments",
          "Integrated Resource Management Planning",
          "Permit Acquisition",
          "State/Tribal Historic Preservation Office (SHPO/THPO) Coordination and Consultation",
          "Formal Scoping",
          "Construction and Mitigation Monitoring",
          "Land Use Impacts",
          "Expert Testimony",
          "Recreation Planning",
          "Environmental Compliance",
          "Feasibility and Background Studies"
        ]
      },
      {
        id: 4,
        names: ["History", "Ethnography"],
        capabilities: [
          "Archival Research",
          "Ethnographic Research and Field Studies",
          "Oral History",
          "Genealogical Research",
          "Historical Contexts",
          "Land Use History"
        ]
      },
      {
        id: 5,
        names: ["Reporting"],
        capabilities: [
          "FCC 621/620 Forms",
          "Department of Parks and Recreation (DPR) 523 forms",
          "Research Reports",
          "Preliminary Reports",
          "Pedestrian Survey Reports ",
          "Monitoring Reports",
          "Letter Reports",
          "Subsurface Probing Reports",
          "Testing and Evaluation Reports",
          "Data Recovery Reports",
          "Damage Assessment Reports"
        ]
      },
      {
        id: 6,
        names: ["GIS Mapping", "GPS"],
        capabilities: [
          "Geographical Information Systems (GIS)",
          "Predictive / Sensitivity Modeling",
          "Project / Site Area Mapping Services",
          "Global Positioning Systems (GPS) – Trimble and Garmin Receivers",
          "Pathfinder Office and TerraSync"
        ]
      },
      {
        id: 7,
        names: ["Compliance"],
        capabilities: [
          "National Environmental Policy Act (NEPA)",
          "National Historic Preservation Act (NHPA) - Sections 106 and 110 Compliance",
          "Transportation Section 4(f) Compliance",
          "Local Agency Compliance",
          "Native American Graves Protection and Repatriation Act (NAGPRA)",
          "Archaeological Resources Protection Act (ARPA)",
          "California Environmental Quality Act (CEQA)"
        ]
      },
    ]
  }
}

export default Api;

import React, { Component } from 'react';
import '../css/ServicesGallery.css';
import { Card, CardImg, CardImgOverlay } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link'

class ServicesGallery extends Component {
  render() {
    let images = this.props.images.map(image => {
      if (image.overlay !== undefined) {
        return (
          <Card key={image.id} className={"services-" + image.id}>
            <CardImg width="100%" height="100%" src={image.url} alt={image.alt} />
            <CardImgOverlay>
              <div className="overlay">
                <div className="overlay-content">
                  <h6 className="font-accent">{image.overlay.title}</h6>
                  <span className="font-light">{image.overlay.details}</span>
                </div>
              </div>
            </CardImgOverlay>
          </Card>
        )
      } else {
        return (
          <Card key={image.id} className={"services-" + image.id}>
            <CardImg width="100%" height="100%" src={image.url} alt={image.alt} />
          </Card>
        );
      }
    });

    return (
      <div className="ServicesGallery">
        <div className="grid-2">
          {images[0]}
          {images[1]}
        </div>

        <div className="grid-3">
          {images[2]}
          {images[3]}
          {images[4]}
          <Link smooth to="/services#services"><span className="font-accent color-primary">AND MORE.</span></Link>
        </div>
      </div>
    );
  }
}

export default ServicesGallery;

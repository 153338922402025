import React, { Component } from 'react';
import Member from './Member'
import '../css/Team.css';

class Team extends Component {
  render() {
    let principals = this.props.members.principals.map((principal,index) => {
      return <div key={"principal-" + index} className="Member-container"><Member data={principal}/></div>
    });
    let staff = this.props.members.staff.map((member,index) => {
      return <div key={"staff-" + index} className="Member-container"><Member data={member}/></div>
    });
    return (
      <div className="Team content">
        <div className="intro" id="team">
          <div className="title font-accent">
            <h1>Meet the EDS Team</h1>
          </div>
          <div className="description font-primary font-light">
            <p>The Owners of EDS have a shared determination of professionalism in everything we do. We build lasting and long-term relationships with our employees and value positive attitudes, teamwork, and integrity.</p>
          </div>
          <div className="intro-filter"></div>
        </div>
        <div className="members">
          <div>
            <h3>Principals</h3>
          </div>
          <div className="gallery">
            {principals}
          </div>
          <div>
            <h3>EDS Staff</h3>
          </div>
          <div className="gallery">
            {staff}
          </div>
        </div>
      </div>
    );
  }
}

export default Team;

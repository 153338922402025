import React, { Component } from 'react';
import {Route, HashRouter, Switch} from 'react-router-dom';
import Nav from './Nav';
import Team from './Team';
import Owner from './Owner';
import Home from './Home';
import Projects from './Projects';
import Blog from './Blog';
import Services from './Services';
import Footer from './Footer';
import Api from './Api';
import '../css/App.css';
import 'react-image-lightbox/style.css';

class App extends Component {
  constructor() {
    super();
    this.apiClient = new Api();
    this.state = {
      introTitle: "",
      introMessage: "",
      footer: {},
      navLinks: [],
      projects: [],
      posts: [],
      images: {
        projects: [],
        services: []
      },
      services: []
    };
  }

  componentWillMount() {
    this.setState({
      intro: this.apiClient.getIntro(),
      navLinks: this.apiClient.getNavLinks(),
      images: this.apiClient.getImages(),
      projects: this.apiClient.getProjects(),
      posts: this.apiClient.getPosts(),
      footer: this.apiClient.getFooter(),
      services: this.apiClient.getServices(),
      teamMembers: this.apiClient.getTeamMembers()
    });
  }

  render() {
    return (
      <div className="App">
        <HashRouter><Nav links={this.state.navLinks} /></HashRouter>
        <HashRouter basename="/">
          <Switch>
            <Route exact path='/' render={props => <Home title={this.state.intro.title} message={this.state.intro.message} images={this.state.images} about={this.state.intro.aboutMessage}/>} />
            <Route path='/projects' render={props => <Projects projects={this.state.projects} />} />
            <Route path='/team' render={props => <Team members={this.state.teamMembers} />} />
            <Route path='/owners' render={props => <Owner members={this.state.teamMembers} />} />
            <Route path='/blog' render={props => <Blog posts={this.state.posts} />} />
            <Route path='/services' render={props => <Services services={this.state.services} />} />
          </Switch>
        </HashRouter>
        <Footer data={this.state.footer} />
      </div>
    );
  }
}

export default App;

import React, { Component } from 'react';
import ProjectThumbnail from './ProjectThumbnail'
import { CardDeck } from 'reactstrap';
import '../css/Projects.css';

class Projects extends Component {
  render() {
    let projects = this.props.projects.map(project => {
      return (
        <ProjectThumbnail key={project.id} images={project.images} title={project.title}  type={project.type}/>
      )
    });
    return (
      <div className="Projects content">
        <h1 className="font-accent">Highlighted Projects</h1>
        <CardDeck>
          {projects}
        </CardDeck>
      </div>
    );
  }
}

export default Projects;

import React, { Component } from 'react';
import { Card, CardImg, CardImgOverlay, CardBody, CardTitle, CardSubtitle, CardText } from 'reactstrap';
import '../css/PostThumbnail.css';

class PostThumbnail extends Component {
  render() {
    let imgIndex = Math.floor(Math.random() * this.props.post.images.length);
    let image = this.props.post.images[imgIndex];

    return (
      <Card className="PostThumbnail">
        <CardImg width="100%" height="100%" src={image.url} alt={image.alt} />
        <CardImgOverlay className="img-filter"></CardImgOverlay>
        <CardBody>
          <CardTitle className="font-primary font-medium font-italic">{this.props.post.title}</CardTitle>
          <CardSubtitle className="font-accent font-light color-secondary">{this.props.post.created_at}</CardSubtitle>
          <CardText className="font-primary font-light">{this.props.post.content}</CardText>
        </CardBody>
      </Card>
    );
  }
}

export default PostThumbnail;

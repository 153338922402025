import React, { Component } from 'react';
import { Button } from 'reactstrap';
import '../css/Footer.css';

class Nav extends Component {
  render() {
    return (
      <div className="Footer">
        <div>
          <div><h6 className="font-accent">{this.props.data.company}</h6></div>
          <div className="font-primary font-medium">
            <div>
              <span className="Footer-label">Address: </span>
              <span>{this.props.data.address}</span></div>
            <div>
              <span className="Footer-label">Office Phone: </span>
              <span>{this.props.data.phone}</span>
            </div>
            <div>
              <span className="Footer-label">Email: </span>
              <span>{this.props.data.email}</span>
            </div>
          </div>
        </div>
        <div >
          <Button size='lg' className='font-primary' href="mailto:info@evans-deshazo.com?subject=Quote">Request a Quote</Button>
        </div>
      </div>
    );
  }
}

export default Nav;

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/index.css';
import './css/BootstrapOverride.css';
import App from './js/App';
import registerServiceWorker from './registerServiceWorker';

require('typeface-roboto')

ReactDOM.render(
  <BrowserRouter basename="/">
    <App />
  </BrowserRouter>
  , document.getElementById('root')
);

registerServiceWorker();

import React, { Component } from 'react';
import '../css/Service.css';

class Service extends Component {
  render() {
    let anchor = this.props.names[0].replace(" ", "")
    let title = this.props.names.map((name,index,array) => {
      if (index !== array.length - 1) {
        name = name + "/";
      }

      return <h1 key={name}>{name}</h1>
    });
    let capabilities = this.props.capabilities.map((capability, index, array) => {
      return <li key={capability}>{capability}</li>
    });

    if (this.props.titleRight) {
      return (
        <div className="Service" id={anchor}>
          <div className="description font-primary font-light">
            <ul>
              {capabilities}
            </ul>
          </div>
          <div className="title text-right font-accent">
            {title}
          </div>
        </div>
      );
    } else {
      return (
        <div className="Service" id={anchor}>
          <div className="title font-accent">
            {title}
          </div>
          <div className="description font-primary font-light">
            <ul>
              {capabilities}
            </ul>
          </div>
        </div>
      );
    }
  }
}

export default Service;

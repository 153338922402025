import React, { Component } from 'react';
import '../css/Member.css';

function MemberImage(props) {
  if (props.image) {
    return <img src={props.image}></img>
  }

  return <div class="image-none"></div>    
}

class Member extends Component {
  render() {
    let email = ""
    let education = ""
    let positions = this.props.data.positions.map((position, index) => {
      return <li key={index} className="font-italic">{position}</li>
    })
    if (this.props.data.email !== undefined) {
      email = <span className="email font-italic">{this.props.data.email}</span>
    }
    if (this.props.data.education !== undefined) {
      education = (
        <div>
          <h6>Education</h6>
          <span className="font-italic">{this.props.data.education}</span>
        </div>
      )
    }
    return (
      <div className="Member">
        <MemberImage image={this.props.data.image} />
        <h4>{this.props.data.name}</h4>
        {email}
        {education}
        <h6>Position</h6>
        <ul>
          {positions}
        </ul>
        <h6>Experience</h6>
        <span className="font-italic">{this.props.data.experience} Years</span>
      </div>
    );
  }
}

export default Member;

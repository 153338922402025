import React, { Component } from 'react';
import '../css/Post.css';

class Post extends Component {
  render() {
    let postIndex = this.props.posts.findIndex(post => post.id === parseInt(this.props.match.params.id, 0));
    let post = this.props.posts[postIndex]
    let imgIndex = Math.floor(Math.random() * post.images.length);
    let image = post.images[imgIndex]

    if (!post) {
      return <div className="Post"><h1 className="font-accent error-message">Post not found</h1></div>;
    }
    return (
      <div className="Post content">
        <img src={image.url} alt={image.alt} />
        <h3 className="font-primary font-medium font-italic">{post.title}</h3>
        <h5 className="font-accent font-light color-secondary">{post.created_at}</h5>
        <p id="author" className="font-primary font-medium">By {post.author}</p>
        <p className="post-content font-primary font-light">{post.content}</p>
      </div>
    );
  }
}

export default Post;

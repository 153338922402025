import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link'
import '../css/NavDropdown.css';

class NavDropdown extends Component {
  render() {
    let links = this.props.links.map(link => {
      return (
        <Link key={link.text} smooth to={link.url}>{link.text}</Link>
      );
    });

    return (
      <div className="NavDropdown">
        <div className="NavDropdown-content font-primary font-light">
          {links}
        </div>
      </div>
    );
  }
}

export default NavDropdown;

import React, { Component } from 'react';
import '../css/ProjectThumbnail.css';
import { Card, CardImg, CardTitle, CardText, CardImgOverlay } from 'reactstrap';
import Lightbox from 'react-image-lightbox';

class ProjectThumbnail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imgIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { imgIndex, isOpen } = this.state;
    let image = this.props.images[imgIndex]
    let title = this.props.title || image.title
    let type = this.props.type || image.type
    console.log(this.props.images)

    return (
      <Card className="ProjectThumbnail" onClick={() => this.setState({ isOpen: true })}>
        <CardImg width="100%" height="100%" src={image.url} alt={image.alt} />
        <CardImgOverlay className="img-filter">
          <div className="img-details">
            <CardTitle className="font-accent font-light">{title}</CardTitle>
            <CardText className="font-primary">{type}</CardText>
          </div>
        </CardImgOverlay>

        {isOpen && (
            <Lightbox
              imageCaption={this.props.images[imgIndex].caption}
              mainSrc={this.props.images[imgIndex].url}
              nextSrc={this.props.images[(imgIndex + 1) % this.props.images.length].url}
              prevSrc={this.props.images[(imgIndex + this.props.images.length - 1) % this.props.images.length].url}
              onCloseRequest={() => this.setState({ isOpen: false, imgIndex: 0 })}
              onMovePrevRequest={() =>
                this.setState({
                  imgIndex: (imgIndex + this.props.images.length - 1) % this.props.images.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  imgIndex: (imgIndex + 1) % this.props.images.length,
                })
              }
            />
        )}
      </Card>
    );
  }
}

export default ProjectThumbnail;

import React, { Component } from 'react';
import '../css/Owner.css';

class Owner extends Component {
  render() {
    return (
      <div className="Owner content">
        <div className="intro" id="owners">
          <img src={process.env.PUBLIC_URL + '/images/intro-owners-bg.jpg'}></img>
          <div className="title font-accent">
            <h1>Meet The Owners</h1>
            <h2 className="font-italic text-center">Sally and Stacey</h2>
          </div>
          <div className="intro-filter"></div>


        </div>

        <div className="about-container">
          <div className="about">
            <h6>Sally Evans, M.A., RPA, Principal Archaeologist</h6>

            <p>Ms. Evans exceeds the Secretary of Interior's professional qualification standards in Archaeology and is a Registered Professional Archaeologist (RPA #29300590) with over 20 years experience in historic and prehistoric archaeology, cultural resource management (CRM) and Geographical Information System (GIS) technology. Ms. Evans holds a Master of Arts (M.A.) degree in CRM from Sonoma State University with an emphasis in bioarchaeology and a Bachelor of Arts degree (B.A.) in Anthropology from California State University Chico with an emphasis in human osteology and archaeology, as well as specialized training in GIS technology and regulatory compliance under Section 106 of the National Historic Preservation Act (NHPA), the National Environmental Policy Act (NEPA), the California Environmental Quality Act (CEQA) and the Native American Graves Protection and Repatriation Act (NAGPRA). Ms. Evans is also an experienced Project Manager and capable of establishing and tracking schedules and budgets, identifying project needs, directing staff and field operations, and communicating clearly and efficiently with clients, stakeholders, agencies and other cultural resource specialists.</p>
            <p>At EDS, Ms. Evans is responsible for overseeing CRM projects, archaeological studies, and GIS, as well as maintaining the overall scientific integrity of the company and its staff. This includes all aspects of company and project management; fieldwork, including cultural resource identifications, significance evaluations, impact/damage assessments, archaeological monitoring, cultural resource sensitivity training, human versus non-human bone identification, and artifact analysis; the development and maintenance of GIS data and preparation of maps; and the preparation of technical reports including survey reports, research designs, treatment plans, data recovery plans, management plans, and specific Federal agency-required documentation.</p>
            <p>Ms. Evans is a member of the Society for American Archaeology (SAA), Society for California Archaeology (SCA), Association of Environmental Professionals (AEP), National Association of Professional Women, Leadership Santa Rosa Alumni Association (LSRAA), and also serves as Secretary of the Board of Directors of the Museum of the American Indian (MAI), Novato, California.</p>
          </div>

          <div className="about">
            <h6>Stacey De Shazo, M.A., Principal Architectural Historian</h6>
            <span></span>
            <p>Ms. De Shazo is the co-owner and Principal Architectural Historian of EDS. She has a Master’s degree in Historic Preservation from Savannah College of Art and Design, where she received an academic scholarship, and she holds a Bachelor’s degree in Anthropology from San Francisco State University. Ms. De Shazo has over 20 years of experience in the historic architectural and cultural resources survey, identification, and evaluation in California, Oregon, Washington, and Alaska. She has substantial experience in project development and project management historic resource documentation and regulatory compliance on properties ranging from Historic Homesteads to National Register Historic Districts. She has surveyed and evaluated a full-range of historical resources and she has extensive project management skills in budgeting, scoping, and report writing. She has a wide-ranging knowledge of historic context within California, Oregon, and Washington, which includes major trends in immigration, industrialization, agricultural development, transportation, and economic growth associated with cultural resources within the western United States.</p>
            <p>Ms. De Shazo is highly experienced in documentation and compliance projects under National Environmental Protection Act (NEPA). National Historic Preservation Act, Section 106 regulations, and California Environmental Quality Act (CEQA). She has produced numerous feasibility studies, managed Federal projects, and advised local officials on practices and guidelines related regulatory compliance. Her extensive experience on complex historic preservation and cultural resource projects within the western United States, along with her education has allowed her to become an expert and leader on historic architecture and cultural resources</p>
            <p>Ms. De Shazo is currently serving as the Chair for the City of Santa Rosa, Cultural Heritage Board and is also an Adjunct Lecturer at Sonoma State University</p>
          </div>
        </div>

      </div>
    )
  }
}

export default Owner;

import React, { Component } from 'react';
import PostThumbnail from './PostThumbnail'
import { CardColumns } from 'reactstrap';
import { Link } from 'react-router-dom'
import '../css/AllPosts.css';

class AllPosts extends Component {
  render() {

    let posts = this.props.posts.map(post => {
      return (
        <Link to={'/blog/' + post.id} key={post.id}><PostThumbnail post={post} /></Link>
      )
    });
    if (posts.length > 0) {
      return (
        <div className="AllPosts">
          <h1 className="font-accent">Our Blog</h1>
          <CardColumns>
            {posts}
          </CardColumns>
        </div>
      );
    } else {
      return (
        <div className="AllPosts">
          <h1 className="font-accent">Our Blog</h1>
          <p className="font-primary text-center">Coming Soon</p>
        </div>
      );
    }
  }
}

export default AllPosts;

import React, { Component } from 'react';
import AllPosts from './AllPosts'
import Post from './Post'
import {Switch, Route} from 'react-router-dom';
import '../css/Blog.css';

class Blog extends Component {
  render() {
    return (
      <div className="Blog content">
        <Switch>
          <Route exact path='/blog' render={props => <AllPosts posts={this.props.posts} />} />
          <Route path='/blog/:id' render={props => <Post posts={this.props.posts} {...props} />} />
        </Switch>
      </div>
    );
  }
}

export default Blog;

import React, { Component } from 'react';
import Service from './Service'
import '../css/Services.css';

class Services extends Component {
  render() {
    let services = this.props.services.map((service,index) => {
      return <div key={service.id} className="service-container"><Service names={service.names} capabilities={service.capabilities} titleRight={index % 2 === 0}/></div>
    });

    return (
      <div className="Services content">
        <div className="intro" id="services">
          <div className="title font-accent">
            <h1>Services</h1>
          </div>
          <div className="description font-primary font-light">
            <p>Evans & De Shazo, Inc. is a full-service Cultural Resource Management (CRM) company that offers a variety of consulting services throughout California, Oregon, and Washington to help identify, evaluate, mitigate and resolve impacts to significant cultural resources, including prehistoric and historic archaeological sites, historic buildings, structures and objects within the built environment, cultural landscapes, and districts. In addition, we provide geoarchaeological services, osteological services, GIS services and paleontological resource studies.</p>
          </div>
          <div className="intro-filter"></div>
        </div>
        {services}
      </div>
    );
  }
}

export default Services;

import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import NavDropdown from './NavDropdown';
import { Link } from 'react-router-dom'
import '../css/NavButton.css';

class NavButton extends Component {
  render() {
    let email = this.props.url.startsWith("mailto:") ? true : false;
    let dropdownContent = this.props.hasDropdown ? <NavDropdown links={this.props.dropdownLinks} /> : "";
    let dropdownIcon = this.props.hasDropdown ? <FontAwesome name='caret-down' /> : "";

    if (email) {
      return (
        <div className="NavButton">
          <a href={this.props.url} className="NavButton-link" target="_blank" rel="noopener noreferrer">{this.props.text} {dropdownIcon}</a>
          {dropdownContent}
        </div>
      )
    } else {
      return (
        <div className="NavButton">
          <Link to={this.props.url} className="NavButton-link">{this.props.text} {dropdownIcon}</Link>
          {dropdownContent}
        </div>
      );
    }
  }
}

export default NavButton;

import React, { Component } from 'react';
import NavButton from './NavButton';
import { Link } from 'react-router-dom'
import '../css/Nav.css';

class Nav extends Component {
  render() {
    let links = this.props.links.map(link => {
      return (
        <NavButton key={link.text} text={link.text} url={link.url} hasDropdown={link.hasDropdown} dropdownLinks={link.dropdownLinks} />
      );
    });

    return (
      <div className="Nav">
        <Link to="/" className="Nav-logo"><img src={process.env.PUBLIC_URL + '/images/logo.jpg'} alt="Evans & DeShazo Inc. logo"/></Link>
        <div className="Nav-links font-accent font-light">
          {links}
        </div>
      </div>
    );
  }
}

export default Nav;

import React, { Component } from 'react';
import Gallery from './Gallery'
import ServicesGallery from './ServicesGallery'
import '../css/Home.css';

class Home extends Component {
  render() {
    return (
      <div className="Home content">
        <div className="Home-intro">
          <div className="Home-intro-filter"></div>
          <div className="Home-intro-content">
              <div className="Home-tile"></div>
              <p className="Home-message font-primary font-light">{this.props.message}</p>
          </div>
        </div>
        <div className="Home-about">
          <div className="Home-decoration">
            <div className="decorative-bg-light"></div>
            <div className="decorative-bg"></div>
          </div>
          <h3 className="Home-header">Who We Are</h3>
          <p className="Home-about-content font-primary">
            {this.props.about}
          </p>
        </div>
        <div className="Home-services">
          <h3 className="Home-header">Our Services</h3>
          <ServicesGallery images={this.props.images.services} />
        </div>
        <div className="Home-projects">
          <div className="Home-header-projects"><h3>Highlighted Projects</h3></div>
          <Gallery className="Home-gallery-projects" type='uniform-grid' images={this.props.images.projects} />
        </div>
      </div>
    );
  }
}

export default Home;

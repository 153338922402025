import React, { Component } from 'react';
import ProjectThumbnail from './ProjectThumbnail'
import '../css/Gallery.css';
import { CardDeck } from 'reactstrap';

class Gallery extends Component {
  render() {
    let images = this.props.images.slice(0,8).map(image => {
      return (
        <ProjectThumbnail key={image.id} images={image.images} title={image.title}  type={image.type}/>
      );
    });

    return (
      <div className="Gallery">
        <CardDeck>
          {images}
        </CardDeck>
      </div>
    );
  }
}

export default Gallery;
